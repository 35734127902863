<script>
import draggable from "vuedraggable";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Status from "@/helpers/general/status";
import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";
export default {
    props: {
        value: Array,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: ClassicEditor,
            promotion_modality_types: Status.getList('promotion_modality_types'),
        }
    },
    components: {   
        draggable,
        ckeditor: CKEditor.component,
        AddIndividualMediaButton,
    },
    methods: {
        addModality(){
            this.value.push({
                title: null,
                feature_media_id: null,
                feature_media: null,
                description: null,
                start_date: null,
                end_date: null,
                type: "",
                value_without_youth_card: null,
                value_with_youth_card: null,
                action_label: null,
                action_url: null,
                secondary_action_label: 'Más información',
                secondary_action_url: null,
                order: null,
                discount_codes: null,
            });
        },
        deleteModality(key){
            this.value.splice(key, 1);
        },
    },
};
</script>

<template>
    <div>
        <div role="tablist">
            <draggable group="promotion-modalities" :list="value">
                <b-card no-body class="mb-1 shadow-none" v-for="(modality, i) in value" :key="i" :set="vattr = v.promotion.promotion_modalities.$each[i]">
                    <b-card-header header-tag="header" role="tab">
                    <h6 class="m-0">
                        <a
                        v-b-toggle="'modality-accordion-' + i"
                        class="text-dark"
                        href="javascript: void(0);"
                        >{{modality.title || 'Modalidad nueva'}}</a>
                        <div class="float-right">
                            <a
                                href="javascript:void(0);"
                                class="text-secondary"
                                v-b-tooltip.hover
                                title="Ordenar"
                            >
                                <i class="mdi mdi-sort font-size-18"></i>
                            </a>
                            
                            <a
                                href="javascript:void(0);"
                                class="text-danger"
                                v-b-tooltip.hover
                                title="Eliminar"
                                @click="deleteModality(i)"
                            >
                                <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>                                                        
                        </div>  
                    </h6>
                    </b-card-header>
                    <b-collapse :id="'modality-accordion-' + i" visible accordion="modality-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text style="max-height: 50vh; overflow: hidden; overflow-y: auto">
                            <div class="row">
                                <div class="col-12">
                                    <label>Imagen destacada</label>
                                    <AddIndividualMediaButton v-model="modality.feature_media" :uploadModalId="'add-individual-media-modal-modality-feature-media-'+i"></AddIndividualMediaButton>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="weight">Titulo</label>
                                        <input
                                            id="title"
                                            v-model="modality.title"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa el titulo de la modalidad"
                                            :class="{ 'is-invalid': vattr.title.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="type">Tipo</label>
                                        <select v-model="modality.type" class="form-control"
                                            :class="{ 'is-invalid': vattr.type.$error && submitted }">
                                            <option value="">-- Seleccione --</option>
                                            <option v-for="option in promotion_modality_types" v-bind:value="option.id" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>
                                            {{ (modality.type === 'percentage') ? '% Dcto sin carné' : 'Valor sin carné' }}
                                        </label>
                                        <input
                                            id="value_without_youth_card"
                                            v-model="modality.value_without_youth_card"
                                            type="number"
                                            class="form-control"
                                            placeholder="Ingresa un valor"
                                            :class="{ 'is-invalid': vattr.value_without_youth_card.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>
                                            {{ (modality.type === 'percentage') ? '% Dcto con carné' : 'Valor con carné' }}
                                        </label>
                                        <input
                                            id="value_with_youth_card"
                                            v-model="modality.value_with_youth_card"
                                            type="number"
                                            class="form-control"
                                            placeholder="Ingresa un valor"
                                            :class="{ 'is-invalid': vattr.value_with_youth_card.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="weight">Fecha inicial</label>
                                        <input
                                            id="start_date"
                                            v-model="modality.start_date"
                                            type="date"
                                            class="form-control"
                                            placeholder="Ingresa el titulo de la modalidad"
                                            :class="{ 'is-invalid': vattr.start_date.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="weight">Fecha final</label>
                                        <input
                                            id="end_date"
                                            v-model="modality.end_date"
                                            type="date"
                                            class="form-control"
                                            placeholder="Ingresa el titulo de la modalidad"
                                            :class="{ 'is-invalid': vattr.end_date.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Texto botón acción principal</label>
                                        <input
                                            id="action_label"
                                            v-model="modality.action_label"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa un texto"
                                            :class="{ 'is-invalid': vattr.action_label.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Url botón acción principal</label>
                                        <input
                                            id="action_url"
                                            v-model="modality.action_url"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa una url"
                                            :class="{ 'is-invalid': vattr.action_url.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Texto botón acción secundaria</label>
                                        <input
                                            id="secondary_action_label"
                                            v-model="modality.secondary_action_label"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa un texto"
                                            :class="{ 'is-invalid': vattr.secondary_action_label.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Url botón acción secundaria</label>
                                        <input
                                            id="secondary_action_url"
                                            v-model="modality.secondary_action_url"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ingresa una url"
                                            :class="{ 'is-invalid': vattr.secondary_action_url.$error && submitted }"
                                        />
                                    </div>
                                </div>
                                
                                <div class="form-group col-12">
                                    <label>Descripción</label>
                                    <ckeditor :id="'modality-description-' + i" v-model="modality.description" :editor="editor"></ckeditor>
                                </div>
                            </div>
                        </b-card-text>
                    </b-card-body>
                    </b-collapse>
                </b-card>
            </draggable>
        </div>
        <b-alert
            show
            variant="secondary"
            class="text-center"
            v-if="!value.length"
        >
            <a
                href="javascript:void(0);"
                class="text-success"
                @click="addModality()"
            >
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar modalidad
            </a>
            <p class="text-danger">Debes añadir mínimo una modalidad</p>
        </b-alert>
        <div class="text-right" v-if="value.length">
            <a
                href="javascript:void(0);"
                class="text-success"
                @click="addModality()"
            >
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar modalidad
            </a>
        </div>
    </div>
</template>